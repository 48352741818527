import React, { Component } from 'react'

export default class Newsletter extends Component {

  render() {
    return (


<div>

<p></p>


</div>






    )
  }
}