import React, { Component } from "react"
import Helmet from "react-helmet"
import GitHubButton from "react-github-btn"
import { graphql, Link } from "gatsby"
import Layout from "../layout"
import PostListing from "../components/PostListing"

import SEO from "../components/SEO"
import Newsletter from "../components/Newsletter"
import config from "../../data/SiteConfig"

import mcstacker from "../../content/images/mcstacker.svg"

import david from "../../content/images/david-tromholt.jpeg"

export default class Index extends Component {
  render() {
    const { data } = this.props

    const latestPostEdges = data.latest.edges
    const popularPostEdges = data.popular.edges

    return (
      <Layout>
        <Helmet title={`${config.siteTitle} – Code, Design, SEO tutorials`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <div className="elevator">
              <h1 style={{ marginBottom: "1rem" }}>
                Development, Design,&nbsp;SEO
              </h1>
              <p>
                Daily, beginner-friendly tutorials about HTML, CSS, JavaScript
                and other tech topics
              </p>
            </div>
          </div>

          <div className="test-wrapper hide">
            <div className="test-wrapper-inner">
              <div className="newsletter-container flex">
                <img
                  style={{ maxHeight: "85px", borderRadius: "50%" }}
                  src={david}
                  className="newsletter-avatar"
                  alt="David"
                />
                <div className=" flex-column">
                  <h3 style={{ margin: 0 }}>Newsletter</h3>
                  <p style={{ margin: 0 }}>
                    Get tutorials sent to your inbox every week.
                  </p>
                  <p>It takes 2 seconds to subscribe:</p>
                  <iframe
                    className="iframe-test"
                    width="600"
                    height="100"
                    src="https://techstacker.substack.com/embed"
                    frameborder="0"
                    scrolling="no"
                  ></iframe>
                </div>

                {/*
            <img
              style={{ maxHeight: "85px", borderRadius: "50%" }}
              src={david}
              className="newsletter-avatar"
              alt="David"
            />
     */}
              </div>
            </div>
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2 className="font-weight-black">
              Latest Articles
              <Link to="/topics" className="view-all">
                Show all
              </Link>
            </h2>
            <PostListing postEdges={latestPostEdges} />
            <Link to="/topics" className="show-all">
              Show all
            </Link>
          </section>
          {/*
          <section className="section">
            <h2>
              Most Popular
              <Link to="/categories/popular" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={popularPostEdges} />
          </section>
*/}
          <section className="section">
            <div className="mcstacker-dialog flex flex-column items-center">
              <h3
                style={{ marginBottom: "0" }}
              >{`Greetings, I'm McStacker.`}</h3>

              <img
                style={{ maxWidth: "150px" }}
                className=""
                src={mcstacker}
                alt="TechStacker COO"
              />
              <div>
                <span className="block">
                  I’m Chief of Operations on TechStacker..
                </span>
                <span className="block">My mission is to protect you..</span>
                <span className="block">
                  I’ll occasionally pop in to help <strike>me</strike> I mean
                  you..
                </span>
              </div>
            </div>
          </section>
          {/*

          <div className="notebox" style={{ marginTop: "4rem" }}>
            <p className="lg:text-center">
              TechStacker is a proud sponsor of{" "}
              <a href="https://www.makeasplashtour.com/" target="_blank">
                Make a Splash
              </a>{" "}
              <br /> — a mental health awareness organization
            </p>
          </div>
*/}
          {/*
          <section className="section">
            <h2>Open Source Projects</h2>
            <ProjectListing projects={projects} />
          </section>

          <section className="section">
            <h2>Talks</h2>
            <SimpleListing simple data={speaking} />
          </section>
*/}{" "}
          <Newsletter />
        </div>
      </Layout>
    )
  }
}

// How many posts to display '6' before

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 10
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`
